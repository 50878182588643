package the.beginning.of.the.end.theme

import androidx.compose.ui.graphics.Color

object Colors {
    val cream = Color(0xffffefdb)
    val brown = Color(0xff422701)
    val brown2 = Color(0xff3b2301)
    val orange = Color(0xfffcae1d)

}